import React, {Component} from 'react';
import M from 'materialize-css';
import youtuberedes from '../Images/youtube.png'
import faceredes from '../Images/face.png'
import instaredes from '../Images/insta.png'
import whatsredes from '../Images/whats.png'

export default class Redes extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount = () => {
        var elems = document.querySelectorAll('.fixed-action-btn');
        M.FloatingActionButton.init(elems);
    }

    render(){
        return [
            <div style={{marginBottom:'80px', marginRight:'50px'}} class="fixed-action-btn" id="boton-redes">
                <a href="#!" className="btn-floating btn-large light-green darken-3 pulse">
                    <i class="fas fa-phone-alt"></i>
                </a>
                <ul>
                    <li><a rel="noopener noreferrer" target="_blank" class="btn-floating blue" href="https://www.facebook.com/Extintores-de-M%C3%A9xico-121611439701750"><img id="imagenes" id="imagenes" alt="imagen face" src={faceredes} style={{width:'100%'}}/></a></li>
                    <li><a rel="noopener noreferrer" target="_blank" class="btn-floating green" href="https://wa.me/523319333785?text=¡Hola buen día! ¿Me puedes brindar información de los productos?"><img id="imagenes" id="imagenes" alt="imagen whats" src={whatsredes} style={{width:'100%'}}/></a></li>
                </ul>
            </div>
        ]
    }

}