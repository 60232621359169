import React, {Fragment, Component} from 'react';

class Footer extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return(
            <div id="footer" className="row center" style={{color:"white", backgroundColor:"#9E2123", marginBottom:"0px", paddingBottom:"20px"}}>
                <div className="col s12 m4 l4">
                    <p style={{textAlign:"center"}}>Copyright © 2020 Extintores de México</p>
                </div>
                <div className="col s12 m6 l6 row">
                    <div className="col s12 m4 l4">
                        <p style={{fontWeight:"bold", margin:"0 10px"}}>Teléfono:</p>
                        <p style={{margin:"0"}}>
                            Tel <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523319333785?text=¡Hola buen día! ¿Me puedes brindar información de los productos?">(0133)1933 3785</a>,
                        </p>
                        <p style={{margin:"0"}}>
                            Tel <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523316777084?text=¡Hola buen día! ¿Me puedes brindar información de los productos?"> (0133)1677 7084</a>
                        </p>
                    </div>
                    <div className="col s12 m8 l8">
                        <p style={{fontWeight:"bold", margin:"0 10px"}}>Dirección:</p>
                        <p style={{margin:"0 10px"}}>Prolongación avenida del Coli 232, </p>
                        <p style={{margin:"0 10px"}}>CP 45069 Zapopan Jalisco</p>
                    </div>
                </div>
                <div className="col s12 m2 l2">
                    <h5 style={{fontWeight:"bold", textAlign:"center"}}>FOLLOW</h5>
                    <div>
                        <a style={{color:"white", margin:"10px"}} rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Extintores-de-M%C3%A9xico-121611439701750">
                            <i class="fab fa-facebook fa-3x"></i>
                        </a>
                        <a style={{color:"white", margin:"10px"}} rel="noopener noreferrer" target="_blank" href="https://wa.me/523319333785?text=¡Hola buen día! ¿Me puedes brindar información de los productos?">
                            <i class="fab fa-whatsapp fa-3x"></i>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;