import React, {Component, Fragment} from 'react';

class Nosotros extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return(
            <Fragment>
                <div className="fondoNosotros"></div>
                <div className="row center contenedor">
                    <div className="col s12 m6 l6">
                        <h2>NOSOTROS</h2>
                        <p>
                            Somos especialistas en materia de protección contra incendios, brindamos 
                            consultoría en materia de protección civil, venta de botiquines, seguridad 
                            industrial, mantenimiento de extinguidores, capacitación y cursos de rescate 
                            y en general. 
                        </p>
                        <p>
                            En virtud a que hemos fortalecido nuestra presencia en el mercado nacional 
                            como empresa de extintores, mediante la implementación de estrictos controles 
                            de calidad en nuestros productos y servicios, nos hemos lanzado con éxito al 
                            mercado nacional y nos caracteriza la responsabilidad y calidad de productos 
                            y servicios.
                        </p>
                    </div>
                </div>
                <div className="row center contenedor">
                    <div className="col s12 m6 l6">
                        <h4 style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px"}}>Visión:</h4>
                        <p>
                            Ser reconocidos como la empresa de nivel nacional con mayor experiencia solidez y 
                            confiabilidad, líder en la comercialización y capacitación de equipos contra incendios, 
                            cuya marca hace referencia de calidad y confianza. Ofreciéndole a nuestros clientes los 
                            mejores alternativas y soluciones integrales en materia de seguridad, mediante un 
                            servicio profesional, ético y responsable.
                        </p>
                    </div>
                </div>
                <div className="row center contenedor">
                     <div className="col s12 m6 l6">
                        <h4 style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px"}}>Misión:</h4>
                        <p>
                            Comercializar equipos contra incendio, señalamientos, recarga de extintores y 
                            mantenimiento de extintores de la más alta calidad, asumiendo el compromiso de brindar 
                            una solución  integral a los requerimientos del cliente, con el respaldo de suministros 
                            y servicios oportunos, con la garantía de un gran número de clientes satisfechos en 
                            todo el país.
                        </p>
                        <p>
                            Consolidarnos como la mejor opción para las cadenas con presencia a 
                            nivel nacional por nuestra basta experiencia con contratos nacionales e importantes 
                            empresas con presencia en toda la república mexicana.
                        </p>
                    </div>
                    <div className="col s12 m6 l6">
                        <h4 style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px"}}>Filosofía:</h4>
                        <p>
                            Identificar y satisfacer las necesidades del cliente, ofreciendo productos de 
                            seguridad enfocándose en el servicio, precio y calidad a través de la mejora 
                            continua de nuestros productos y servicios. Manejando siempre una ética y 
                            honestidad que nos distingue.
                        </p>
                    </div>
                </div>
            </Fragment>
        );
    };
}

export default Nosotros;