import React, {Fragment, Component}  from 'react';
import ReactDOM from 'react-dom';
import M from 'materialize-css';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import Swal from 'sweetalert2';


class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            dato: 0,
            datos: 0,
            datosArray: [],
            blogs: []

        }
    }

    componentDidUpdate = () => {
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems);
        var blogs = this.props.blogs,
        dato = this.state.dato,
        datos = this.state.datos,
        datosArray = this.state.datosArray,
        blogsArray = [],
        candado = true;
        for (let i = 0; i < blogs.length; i++) {
            //console.log('entro');
            //console.log(dato);
            if (dato < 6 && candado) {
                blogsArray.push(blogs[i]);
            } else {
                candado = false;
                //console.log(dato/6);
                if ((dato/6) === 1) {
                    datos++;
                    datosArray.push(datos);
                }
                dato = 0;
            }
            dato++;
        }
        if (this.state.dato === 0) {
            this.setState({
                dato: dato,
                datos: datos,
                blogs: blogsArray,
                datosArray: datosArray
            }, () => {
                //console.log(this.state);
            });
        }
    }


    datosMostrar = (inicio )=> {
        var blogs = this.props.blogs,
        dato = this.state.dato,
        datos = this.state.datos,
        datosArray = [],
        blogsArray = [],
        candado = true;
        for (let i = inicio; i < blogs.length; i++) {
            //console.log('entro');
            //console.log(dato);
            if (dato < 6 && candado) {
                blogsArray.push(blogs[i]);
            } else {
                candado = false;
                //console.log(dato/6);
                if ((dato/6) === 1) {
                    datos++;
                    datosArray.push(datos);
                }
                dato = 0;
            }
            dato++;
        }
        this.setState({
            dato: dato,
            datos: datos,
            blogs: blogsArray,
            datosArray: datosArray
        }, () => {
            //console.log(this.state);
        });
    }

    consultar = fecha => {
        const { firestore } = this.props;
        var data = this.state.data;

        function consulta1() {
            return new Promise(resolve => {
                firestore.collection("blogs")
                .orderBy("fecha")
                .startAt(new Date(fecha.seconds * 1000))
                .limit(7)
                .get()
                .then(function(querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        resolve(1);
                    } else {
                        //////console.log(querySnapshot.docs.length);
                        var i = 0;
                        querySnapshot.forEach(function(doc) {
                            // doc.data() is never undefined for query doc snapshots
                            if (i !== 0) {
                                var dataDatabase = doc.data()
                                dataDatabase.id = doc.id;
                                data.push(dataDatabase);
                            }
                            i++;
                        });
                        resolve("datos");
                    }
                    
                }).catch(function(error) {
                    //console.log("Error getting document:", error);
                    resolve(1);
                });
            })
        }

        async function obtenerDatos() {
            const acceso = consulta1();
            return acceso;
        }

        obtenerDatos()
        .then(resultado => {
            if (resultado === 1) {
                //////console.log('1');
                Swal.fire(
                    'Error!',
                    'Opss, error al encontrar los datos!',
                    'error'
                )
            } else {
                this.setState({
                    data: data,
                    datos: this.state.datos + 6
                }, () => {
                    //console.log(this.state);
                });
            }
        })
    }

    clickMore = () => {
        var contenedorPaginado = 
        <ul class="pagination">
            <li class="disabled" id="previous"><a onClick={this.back}><i class="material-icons">chevron_left</i></a></li>
            <li class="active"><a onClick={this.click}>3</a></li>
            <li class="waves-effect"><a onClick={this.click}>4</a></li>
            <li class="waves-effect" id="more"><a onClick={this.clickMore}>...</a></li>
            <li class="waves-effect" id="next"><a onClick={this.next}><i class="material-icons">chevron_right</i></a></li>
        </ul>;
        ReactDOM.render(contenedorPaginado, document.getElementById('contenedorPaginado'));
    }

    click = e => {
        var seleccionado = document.getElementsByClassName("active");
        seleccionado[0].className = "waves-effect";
        e.target.parentNode.className = "active";
        this.datosMostrar(parseInt(e.target.childNodes[0].data));
    }

    next = () => {
        var seleccionado = document.getElementsByClassName("active"),
            previous = document.getElementById("previous"),
            next = document.getElementById("next"),
            more = document.getElementById("more");
        if (next.className !== "disabled") {
            seleccionado[0].nextSibling.className = "active";
            //console.log(more.className);
            if (more.className === "active") {
                //console.log('entro');
                this.clickMore();
            } else {
                seleccionado[0].className = "waves-effect";
                if (previous.nextSibling.className === "waves-effect") {
                    previous.className = "waves-effect";
                }
        
                if (next.previousSibling.className === "active") {
                    next.className = "disabled";
                }
            }
        }
        this.datosMostrar(6);
        
    }

    back = () => {
        var seleccionado = document.getElementsByClassName("active"),
            previous = document.getElementById("previous"),
            next = document.getElementById("next");
        if (previous.className !== "disabled") {
            seleccionado[0].previousSibling.className = "active";
            seleccionado[1].className = "waves-effect";
            if (previous.nextSibling.className !== "waves-effect") {
                previous.className = "disabled";
            }

            if (next.previousSibling.className === "waves-effect") {
                next.className = "waves-effect";
            }
        }
        this.datosMostrar(0);
    }


  render(){
    if (this.state.dato === 0) {
      return (
          <Fragment>
              <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                  <div class="preloader-wrapper big active">
                      <div class="spinner-layer spinner-red darken-4-only">
                          <div class="circle-clipper left">
                              <div class="circle"></div>
                          </div>
                          <div class="gap-patch">
                              <div class="circle"></div>
                          </div>
                          <div class="circle-clipper right">
                              <div class="circle"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </Fragment>
      );
  } else {
    return (
      <Fragment>

        <div style={{display:"flex", justifyContent:"center", marginTop:"5%"}}>
            <h2 style={{fontWeight:"bold"}}>BLOG</h2>
        </div>

        <div style={{display:"flex", justifyContent:"center", marginTop:"2%", marginBottom:"5%"}}>
            <h5 style={{textAlign:"center", width:"80%"}}>
                Entra y descubre todo lo que tenemos para ofrecerte dentro de nuestros blogs , contamos con la 
                información mas relevante, entretenida e interesante para que estés al tanto en todo lo 
                relacionado con extintores, equipo de protección y cursos de seguridad empresarial que 
                cualquier persona debería de saber.
            </h5>
        </div>

        
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', justifyItems:'center', margin:'0 5%'}}>
            {this.state.blogs === [] ? (
                <h1>PROXIMAMENTE...</h1>
            ) : (
                this.state.blogs.map(producto => (
                    <a href={`/blog/${producto.id}`}>
                        <div id="contenedorBlog">
                            <img id="imagenBlog" src={producto.urlImagen} alt={producto.titulo} />
                            <h4 id="tituloBlog" >{producto.titulo.toUpperCase()}</h4>
                        </div>
                    </a>
                ))
            )}
        </div>

        <ul class="pagination center-align" id="active">
            <li class="disabled" id="previous"><a onClick={this.back}><i class="material-icons">chevron_left</i></a></li>
            <li class="active"><a onClick={this.click}>1</a></li>
            {
                /*this.state.datosArray.map(dato => (
                    <li class="waves-effect"><a onClick={this.click}>2</a></li>
                    <li class="waves-effect"><a onClick={this.click}>{dato + 1}</a></li>
                    <li class="waves-effect" id="more"><a onClick={this.clickMore}>...</a></li>
                ))*/
            }
            <li class="waves-effect" id="next"><a onClick={this.next}><i class="material-icons">chevron_right</i></a></li>
        </ul>

        <div className="container" id="collapsiblePadre" >
            <ul class="collapsible" id="collapsible" style={{margin:"2%", width:"48%"}}>
                <li>
                    <div class="collapsible-header" style={{fontWeight:"bold", color:"black"}}><i class="fas fa-angle-right"></i>¿Qué es el fuego y cuáles son los tipos de fuegos?</div>
                    <div class="collapsible-body">
                        <h5>Fuego</h5>
                        <p>
                            Es la oxidación rápida de los materiales combustibles con desprendimiento de luz 
                            y calor. Este fenómeno consiste en una reacción química de transferencia electrónica, 
                            con una alta velocidad.
                        </p>
                        <p>
                            <span style={{color:"#FE2621"}}><i class="fas fa-circle"></i>Fuego clase A: </span>Es aquel que se presenta 
                            en material combustible sólido, generalmente de naturaleza orgánica, y que su combustión se realiza 
                            normalmente con formación de
                        </p>
                        <p>
                            <span style={{color:"#FE2621"}}><i class="fas fa-circle"></i>Fuego clase B: </span>Es aquel que se presenta 
                            en líquidos combustibles e inflamables y gases inflamables.
                        </p>
                        <p>
                            <span style={{color:"#FE2621"}}><i class="fas fa-circle"></i>Fuego clase C: </span>Es aquel que involucra 
                            aparatos, equipos e instalaciones eléctricas energizadas.
                        </p>
                        <p>
                            <span style={{color:"#FE2621"}}><i class="fas fa-circle"></i>Fuego clase D: </span>Es aquel en el que 
                            intervienen metales combustibles, tales como el magnesio, titanio, circonio, sodio, litio y potasio.
                        </p>
                        <p>
                            <span style={{color:"#FE2621"}}><i class="fas fa-circle"></i>Fuego clase K: </span>Es aquel que se presenta 
                            básicamente en instalaciones de cocina, que involucra sustancias combustibles, tales como aceites y grasas 
                            vegetales o animales. Los fuegos clase K ocurren en los depósitos de grasa semipolimerizada, y su 
                            comportamiento es distinto a otros combustibles.
                        </p>
                    </div>
                </li>
                <li>
                    <div class="collapsible-header" style={{fontWeight:"bold", color:"black"}}><i class="fas fa-angle-right"></i>¿Qué es un extintor y cómo elegir el equipo?</div>
                    <div class="collapsible-body">
                        <p>
                            El extintor es un aparato que contiene un agente extinguidor (producto cuya acción provoca la extinción) 
                            en su interior, que puede ser proyectado o dirigido sobre un incendio por acción de una presión interna, 
                            con el fin de apagar el fuego en su fase inicial, el tipo de extintor se elige de acuerdo al tipo de fuego.
                        </p>
                        <p style={{color:"#FE2621"}}>Tabla I</p>
                        <p>Clase de Fuego y Agente Extintor Aplicable. Publicado en el Diario Oficial de la Federación de 25 de enero de 1988.</p>
                        <p>
                            * El uso de los agentes limpios a base de gases halón, se ha venido restringiendo gradualmente hasta que llegue a eliminarse 
                            por completo, en cumplimiento a lo dispuesto por el Protocolo de Montreal1, debido a que son compuestos que dañan la 
                            capa de ozono de la atmósfera.
                        </p>
                        <p style={{color:"#FE2621"}}>Tabla II</p>
                        <p>
                            Características de los Extintores de Polvo Químico Seco. Extintores a base de polvo químico seco, capacidad nominal 
                            de los extintores de polvo químico seco, de su alcance y tiempos de descarga.
                        </p>
                        <p>* Los extintores de más de 20 kg deben ser móviles (sobre ruedas).</p>
                    </div>
                </li>
                <li>
                    <div class="collapsible-header" style={{fontWeight:"bold", color:"black"}}><i class="fas fa-angle-right"></i>¿De quién es responsabilidad?</div>
                    <div class="collapsible-body">
                        <p>Del patrón o responsable de la propiedad.</p>
                    </div>
                </li>
            </ul>

            <ul class="collapsible" id="collapsible" style={{margin:"2%", width:"48%"}}>
                <li>
                <div class="collapsible-header" style={{fontWeight:"bold", color:"black"}}><i class="fas fa-angle-right"></i>
                    ¿Por qué hay que realizar el control periódico, mantenimiento y recarga de extintores?
                </div>
                    <div class="collapsible-body">
                        <p>
                            El control periódico, mantenimiento y\o recarga de los extintores además de ser una cuestión de seguridad y prevención es 
                            un requerimiento legal que rige en todo el territorio nacional y aplica en todos los centros de trabajo según la NOM-002-STPS 
                            -2010, quienes así no lo hagan serán expuestos a posibles sanciones y multas.
                        </p>
                    </div>
                </li>
                <li>
                    <div class="collapsible-header" style={{fontWeight:"bold", color:"black"}}><i class="fas fa-angle-right"></i>¿Cuando realizar el mantenimiento y recarga?</div>
                    <div class="collapsible-body">
                        <p>
                            En el punto 7.18 de la NOM -002-STPS-2010 DICE : Proporcionar mantenimiento a los extintores como resultado de las revisiones mensuales. 
                            Dicho mantenimiento deberá estar garantizado conforme a lo establecido en la NOM-154-SCFI-2005 o las que lo sustituyan y habrá de 
                            proporcionarse al menos una vez por año. Cuando los extintores se sometan a mantenimiento deberán ser remplazados en su misma ubicación 
                            por otros cuando menos del mismo tipo y capacidad.
                        </p>
                        <p>
                            En el punto 7.19 de la NOM-002-STPS -2010 DICE: Proporcionar la recarga a los extintores después de su uso y en sus caso como resultado 
                            del mantenimiento la cual deberá estar garantizada de acuerdo con los establecido en la NOM-154-SCFI-2005 o las que la sustituyan.
                        </p>
                    </div>
                </li>
                <li>
                    <div class="collapsible-header" style={{fontWeight:"bold", color:"black"}}><i class="fas fa-angle-right"></i>¿De quién es responsabilidad?</div>
                    <div class="collapsible-body">
                        <p>
                            En la NOM-002-STPS-2010 en el apartado 7.2 dice elaborar un programa anual de revisión mensual de extintores vigilando los 
                            siguientes puntos: Ubicación aduecuada. Señalización.
                        </p>
                        <p>
                            <i class="fas fa-circle fa-xs"></i> Que los equipos cuenten con el sello de garantía. <br />
                            <i class="fas fa-circle fa-xs"></i> Que la aguja del manómetro indique en verde. (Operable) <br />
                            <i class="fas fa-circle fa-xs"></i> Verificar las ruedas en los extintores móviles. <br />
                            <i class="fas fa-circle fa-xs"></i> Que no cuenten con daños físicos los equipos. <br />
                            <i class="fas fa-circle fa-xs"></i> Que la etiqueta, placa se encuentre legible y sin alteraciones. <br />
                            <i class="fas fa-circle fa-xs"></i> Que la etiqueta cuente con la información vigente después de cada mantenimiento. <br />
                        </p>
                        <p>
                            (Nombre, denominación o razón social así como su domicilio y teléfono del prestador del servicio)
                        </p>
                        <p>
                            <i class="fas fa-circle fa-xs"></i> Capacidad nominal en kg o litros y el nombre del agente extintor. <br />
                            <i class="fas fa-circle fa-xs"></i> Las instrucciones de operación apoyada mediante figuras o símbolos. <br />
                            <i class="fas fa-circle fa-xs"></i> La clase de fuego que está destinado el equipo. <br />
                            <i class="fas fa-circle fa-xs"></i> Contra indicaciones de uso. <br />
                            <i class="fas fa-circle fa-xs"></i> El mes y año del último servicio. <br />
                            <i class="fas fa-circle fa-xs"></i> La contraseña oficial de la NOM-154-SCFI-2005. <br />
                            <i class="fas fa-circle fa-xs"></i> Que los equipos que indica la norma oficial que lleven collarín lo incluyan. Lo 
                                extintores de polvo químico seco no aplica para equipo nuevo. <br />
                            <i class="fas fa-circle fa-xs"></i> Este registro de debe contar con el nombre de la persona que realizo el servicio. <br />
                            <i class="fas fa-circle fa-xs"></i> Anomalias detectadas y el seguimiento de las mismas. <br />
                        </p>
                    </div>
                </li>
            </ul>
        </div>

      </Fragment>
    );
  }
  }
}


export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'blogs',
            orderBy: "fecha",
            limit: 12
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        blogs: ordered.blogs
    }))
)(App);