import React, {Fragment, Component}  from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';



class App extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }



  render(){
    if (!this.props.producto) {
      return (
          <Fragment>
              <div className="container center-align" style={{marginBottom:'20%'}}>
                  <div class="preloader-wrapper big active">
                      <div class="spinner-layer spinner-red darken-4-only">
                          <div class="circle-clipper left">
                              <div class="circle"></div>
                          </div>
                          <div class="gap-patch">
                              <div class="circle"></div>
                          </div>
                          <div class="circle-clipper right">
                              <div class="circle"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </Fragment>
      );
  } else {
    return (
      <div className="contenedor" >

          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', justifyItems:'center', margin:'2% 5%'}}>
            <h2 style={{textAlign:'center', fontWeight:'bold'}}>Productos {this.props.match.params.nombre}</h2>
          </div>

          <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', justifyItems:'center', margin:'0 5%'}}>
            {this.props.producto.map(producto => (
              <div id="imagenProducto" style={{width:'350px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'20px'}}>
                <img src={producto.urlImagen} alt={producto.nombre} style={{width:'100%'}} />
                <p style={{textAlign:"center", fontWeight:'bold'}}>{producto.nombre}</p>
                  {producto.urlPDF !== undefined ? (
                    <a href={producto.urlPDF} target="_blank" id="font-button" style={{width:"200px", height:"36px", margin:'0 50px', background:'#FE2621', color:"white", textAlign:"center", borderRadius:"5px"}}>VER CATALOGO</a>
                  ) : (
                    <p style={{margin:"0"}}>ESPERALO</p>
                  )}
              </div>
            ))}
          </div>

      </div>
    );
  }
  }
}


export default compose(
  firestoreConnect(props => [ 
      {
          collection: 'producto',
          where: [
            ['subCategoria', '==', props.match.params.id ]
          ],
          //orderBy:"producto"
      }
  ]),
  connect(({ firestore: { ordered }}, props ) => ({
    producto: ordered.producto
  }))
)(App);