import React, {Fragment} from 'react';

import img5 from '../Images/5.png';

const Servicios = () => {
    return ( 
        <Fragment>

            <div className="contenedor center row">
                <h3 style={{textAlign:"center"}}>Servicios</h3>
                <div className="col s12 m6 l6">
                    <p style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px"}}>
                        Recarga y mantenimiento a extintores
                        y equipos contra incendio
                    </p>
                    <p>
                        1. Recarga de extintores. <br />
                        2. Mantenimiento de Extintores.
                        3. Recarga y mantenimiento a cualquier tipo de extintor o equipo contra incendio. <br />
                        4. Instalación de extintores y reubicación de equipo. <br />
                        5. Servicio integral de mantenimiento para la prevención de incendios. <br />
                        6. Revisiones periódicas. <br />
                        7. Pruebas hidrostáticas. <br />
                        8. Mantenimiento a hidrantes y mangueras contra incendio. <br />
                    </p>
                    <p style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px"}}>
                        Suministro y aplicación
                        y equipos contra incendio
                    </p>
                    <p>
                        1. Retardante de flama. <br></br>
                        2. Cinta antiderrapante. <br></br>
                        3. Detectores de humo. <br></br>
                        4. Señalamientos prohibitivos, preventivos, restrictivos, obligatorios, 
                        precautorios, de emergencia, informativos y de creación personalizada.
                    </p> 
                </div>
                <div className="col s12 m6 l6">
                    <img src={img5} alt="que hacer en caso de incendio" style={{width:"100%"}} />
                </div>
            </div>
            <div className="contenedor center">
                <p style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px", display:"inline"}}>
                    Capacitación
                </p>
                <p>
                    1. Primeros auxilios. <br />
                    2. Prevención y combate de incendios. <br />
                    3. Sistemas de evacuación.<br />
                    4. Formación de brigadas.<br />
                    5. Manejo de materiales peligrosos.<br />
                    6. Ergonomía.<br />
                    7. Seguridad e higiene.<br />
                    8. Protección civil.<br />
                </p>
                <p style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px", display:"inline"}}>
                    Protección Civil
                </p>
                <p>
                    1. Programa interno de Protección Civil. <br></br>
                    2. Programa especifico de 
                    Protección Civil.                        <br></br>
                    3. Planes y programas de emergencia.     <br></br>
                    4. Carta de corresponsabilidad.          <br></br>
                    5. Implementación de programa           
                    interno de PC.                           <br></br>
                    6. Formación, integración y 
                    capacitación de brigadas.                <br></br>
                    7. Actualización de plan de 
                    Protección Civil.
                </p>
                <p style={{fontWeight:"bold", backgroundColor:"#fff", padding:"20px", color:"#000", borderRadius:"20px"}}>
                    Marcaje y señalización de estacionamientos,
                    banquetas, puntos de reunión, discapacitados, etc.
                    y equipos contra incendio
                </p>
                <p>
                    1. Servicio experto de señalización o balizamiento de estacionamientos. <br></br>
                    2. Diseños de marcaje de estacionamiento. <br></br>
                    3. Designación de cajones para estacionamiento. <br></br>
                    4. Punto de reunión. <br></br>
                    5. Marcaje industrial y de tubería.
                </p> 
            </div>

            <div className="contenedor" style={{marginTop:"5%"}}>
                <h4>Ademas contamos con las capacitacion en las siguientes normas de seguridad de la STPS</h4>
                <h5 style={{fontWeight:"bold", textAlign:"center"}}>Contamos con dictamen vigente NOM-154-SCFI-2005</h5>
                <p>Capacitación en temas de Seguridad diferentes Normas de las STPS</p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-026-STPS-2008</p>
                <p>
                    Es una norma oficial mexicana sobre los colores y señales de seguridad e higiene así como la 
                    identificación de riesgos en tuberías por fluidos conducidos en tuberías. Como todas las normas 
                    de la Secretaría del Trabajo tiene carácter obligatorio dentro de todo el territorio nacional 
                    mexicano. Su última versión fue publicada en el año 2008.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-009-STPS-2011</p>
                <p>
                    Es una norma oficial mexicana en materia de prevención de riesgos relacionados con los trabajos en 
                    alturas o trabajos verticales. Es obligatorio su cumplimiento dentro del territorio nacional mexicano.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-019-STPS-2011</p>
                <p>
                    Es una norma oficial mexicana de seguridad que regula las especificaciones técnicas y disposiciones 
                    generales para la limpieza y control de remanentes de substancias y residuos peligrosos en las 
                    unidades que transportan materiales y residuos peligrosos. Al margen un sello con el Escudo Nacional, 
                    que dice: Estados Unidos Mexicanos.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-001-STPS-2008</p>
                <p>
                    Es una norma oficial mexicana de seguridad que regula las condiciones de seguridad e higiene en 
                    todos los edificios, locales, instalaciones y áreas de los centros de trabajo. Esta norma es de 
                    carácter obligatorio dentro de todo el territorio nacional mexicano. Su última versión fue 
                    publicada el 24 de noviembre de 2008 por lo que el título actual completo de la norma es 
                    NOM-001-STPS-2008 y entró en vigor el 23 de enero de 2009.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-002-STPS-2010</p>
                <p>
                    Es una norma oficial mexicana en materia de prevención y protección contra incendios de carácter 
                    obligatorio dentro de todo el territorio nacional mexicano. Su última versión fue publicada en el 
                    año 2010 comenzando a tener vigencia a partir del 9 de junio de 2011
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-004-STPS-1999</p>
                <p>
                    Es una norma oficial mexicana en materia de prevención de accidentes relacionados con el uso de maquinaria. 
                    Es una norma de carácter obligatorio dentro de todo el territorio nacional mexicano. Es una de las normas 
                    vigentes desde hace mucho tiempo ya que su última versión fue publicada desde 1999.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-006-STPS-2000</p>
                <p>
                    Es una norma oficial mexicana en materia de prevención de riesgos laborales relacionados con el manejo y 
                    almacenamiento de materiales. Es una norma de carácter obligatorio dentro de todo el territorio nacional 
                    mexicano. Los riesgos que regula comprenden desde las cargas manuales realizadas por un trabajador hasta 
                    el uso de herramientas y maquinarias como polipastos, carretillas, grúas, patines y montacargas. Su 
                    versión vigente data del año 2014.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-017-STPS-2008</p>
                <p>
                    Es una norma oficial mexicana en materia de selección, uso y manejo de Equipo de protección personal en 
                    los centros de trabajo. Es una norma de carácter obligatorio dentro de todo el territorio nacional mexicano. 
                    La versión más reciente de esta norma es la publicada en el año 2008 por lo que el nombre completo de la 
                    norma es NOM-017-STPS-2008.
                </p>
                <p style={{fontWeight:"bold"}}>Cumplimiento de la NOM-018-STPS-2015</p>
                <p>
                    Es una norma oficial mexicana que establece un sistema armonizado para la identificación y comunicación de 
                    peligros derivados por sustancias químicas peligrosas en aquellos centros de trabajo donde se utilicen este 
                    tipo de sustancias. La norma concuerda parcialmente con la quinta edición del Sistema globalmente armonizado 
                    de clasificación y etiquetado de productos químicos (SGA o GHS por sus siglas en inglés) que publica la ONU.
                </p>
            </div>
        </Fragment>
     );
}
 
export default Servicios;