import React, {Fragment, Component}  from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';


class App extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  componentDidUpdate = () => {
    console.log(this.props);
  }


  render(){
    if (!this.props.subCategoria) {
      return (
          <Fragment>
              <div className="container center-align" style={{marginBottom:'20%'}}>
                  <div class="preloader-wrapper big active">
                      <div class="spinner-layer spinner-red darken-4-only">
                          <div class="circle-clipper left">
                              <div class="circle"></div>
                          </div>
                          <div class="gap-patch">
                              <div class="circle"></div>
                          </div>
                          <div class="circle-clipper right">
                              <div class="circle"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </Fragment>
      );
  } else {
    return (
      <Fragment>

          <div className="contenedor" style={{display:'flex', flexWrap:'wrap', justifyContent:'center', justifyItems:'center', margin:'5%'}}>
            <h1 style={{textAlign:'center', fontWeight:'bold'}}>Productos Extintores</h1>
            <p style={{textAlign:'center', fontSize:'20px'}}>
              Trabajamos con todas las normativas oficiales mexicanas vigentes de protección civil. 
              Contamos con dictamen de cumplimiento de la Nom-154-SCFI-2005. 
            </p>
            <p style={{textAlign:'center', fontSize:'20px'}}>
              Somos una empresa certificada, que cumple con las normas y estándares oficiales en 
              el mantenimiento, recarga, venta de extintores con seguridad proporcionando un soporte 
              técnico con calidad y excelente servicio. Siempre nos caracteriza que brindamos atención 
              personalizada en todo momento. 
            </p>
            <p style={{textAlign:'center', fontSize:'20px'}}>
              Presentamos nuestro catálogo con todos nuestros productos, somos seguridad industrial.
            </p>
          </div>

          {this.props.categoria.map(categoria => (
            <div>
              <h2 style={{textAlign:"center"}}>{categoria.nombre}</h2>
              <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', justifyItems:'center', margin:'0 5%'}}>
                {this.props.subCategoria.map(producto => (
                  producto.categoriaId ===categoria.id ? (
                    <div id="imagenProducto" style={{width:'350px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'20px'}}>
                      <img src={producto.urlImagen} alt={producto.subCategoria} style={{width:'100%'}} />
                      <p style={{textAlign:"center", fontWeight:'bold'}}>{producto.subCategoria}</p>
                      <a href={`/producto/${producto.subCategoria}/${producto.id}`} id="font-button" style={{width:"200px", height:"36px", margin:'0 50px', background:'#FE2621', color:"white", textAlign:"center", borderRadius:"5px"}}>VER MÁS...</a>
                    </div>
                  ) : (
                    <Fragment></Fragment>
                  )
                ))}
              </div>
            </div>
          ))}

          <div style={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection:"column", margin:"5%"}}>
            <h4>Ver Catalogo</h4>
            <div className="center-align" style={{width:"203px", height:"260px", border:"1px solid black"}}>
              <a href={this.props.catalogo[0].pdf} style={{border:"1px solid black"}} target="_blanck">
                <img style={{width:"200px", height:"250px"}} src={this.props.catalogo[0].image} alt="catalogo extintores" />
              </a>
            </div>
          </div>

      </Fragment>
    );
  }
  }
}


export default compose(
  firestoreConnect(props => [
      {
        collection: 'catalogo'
      },
      {
        collection: 'categoria'
      },
      {
          collection: 'subCategoria'
      }
  ]),
  connect(({ firestore: { ordered }}, props ) => ({
      catalogo: ordered.catalogo,
      categoria: ordered.categoria,
      subCategoria: ordered.subCategoria
  }))
)(App);