import React, {Fragment, Component}  from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import ReactHtmlParser from 'react-html-parser';
import $ from "jquery";


class App extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    goToByScroll = (id) =>{ 
        $('html,body').animate({scrollTop: $("#blogContenidoContenedor").offset().top},'slow'); 
    }

    componentDidUpdate = () => {
        var foo = document.getElementById("blogContenidoContenedor");
        for (let i = 0; i < foo.children[0].children.length; i++) {
            if (foo.children[0].children[i].children[0] !== undefined) {
                if (foo.children[0].children[i].children[0].tagName === 'A') {
                    $(foo.children[0].children[i].children[0]).attr("target", "_blank");
                }
            }
        }
    }


    render(){
        if (!this.props.blog) {
        return (
            <Fragment>

                <div className="container center-align" style={{marginTop:'20%', marginBottom:'20%'}}>
                    <div class="preloader-wrapper big active">
                        <div class="spinner-layer spinner-red darken-4-only">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="gap-patch">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    } else {
        return (
        <div >

                <div id="contenedor-blog">
                    <img className="imagen-blog" alt="Señales de obligaciones" id="imgSalida" width="100%" src={this.props.blog.urlImagen}></img>
                    <h1 id="titulo-blog">{this.props.blog.titulo.toUpperCase()}</h1>
                    <a id="titulo-blog-icono" onClick={this.goToByScroll} style={{marginTop:"40%"}}>
                        <i class="fas fa-chevron-down fa-3x"></i>
                    </a>
                </div>

                <div id="blogContenidoContenedor">
                    <p id="font-text2">{ReactHtmlParser(this.props.blog.contenido)}</p>
                </div>

        </div>
        );
    }
    }
}


export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'blogs',
            storeAs: 'blog',
            doc: props.match.params.id
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        blog: ordered.blog && ordered.blog[0]
    }))
)(App);