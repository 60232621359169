import React, {Fragment}  from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';

// *** hoja de estilos *** //
import './index.css';
import './blog.css';

// *** Components *** //
import Header1 from './Components/header1';
import Footer from './Components/footer';
import Panel from './Components/panel';
import Redes from './Components/redes';

// *** vistas ***  //
import index from './Views/App';
import blogsVistas from './Views/Blogs';
import blogVista from './Views/blog';
import nosotros from './Views/Nosotros';
import servicios from './Views/Servicios';
import catalogo from './Views/Productos';
import productoVista from './Views/Producto';
import contacto from './Views/contacto';



const App = () =>(
    <Provider store={store}>
        <BrowserRouter>
            <Header1 />
            <Panel />
            <Redes />
            <Fragment>
                {/* vistas */}
                <Route exact path = "/" component = {index} />
                <Route exact path = "/nosotros" component = {nosotros} />
                <Route exact path = "/servicios" component = {servicios} />
                <Route exact path = "/catalogo" component = {catalogo} />
                <Route exact path = "/producto/:nombre/:id" component = {productoVista} />
                <Route exact path = "/contacto" component = {contacto} />
                <Route exact path = "/blogs" component = {blogsVistas} />
                <Route exact path = "/blog/:id" component = {blogVista} />
                {/* fin - vistas */}
            </Fragment>
            <Footer />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(<App />, document.getElementById('root'));