import React, {Fragment, Component}  from 'react';
import Mapa from '../Components/Mapa';
import face from '../Images/face.png';
import insta from '../Images/insta.png';
import whats from '../Images/whats.png';
import youtube from '../Images/youtube.png';




class App extends Component {

    constructor(props){
        super(props);
        this.state = {}
      }

      render (){
          return ( 
            
            <Fragment>


                <div style={{margin:"50px 0"}}>
                    <h4 style={{textAlign:"center"}}>Brindamos sevicio a toda la república.</h4>
                </div>

                <Mapa />

                <div style={{display:"flex", justifyContent:"center", margin:"5% 0"}}>
                    <a style={{color:"#fff", background:'#FE2621', borderRadius:'10px', width:"450px", height:"38px", textAlign:"center", fontWeight:"bold"}} href="https://goo.gl/maps/FxaxyR24agZwCkiw6" rel="noopener noreferrer" target="_blank">BUSCANOS EN GOOGLE MAPS </a>
                </div>

                <div className="row center container" style={{marginTop:"40px"}}>
                    <div id="img-footer-container" className="col s12">
                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/Extintores-de-M%C3%A9xico-121611439701750">  
                        <img style={{maxWidth:'50px'}} id="img-footer" alt='facebook' src={face}/></a>
                    </div>
                </div>

                <div className="divider" style={{margin:'0 200px'}}></div>

                <div id="font" style={{textAlign:"center", marginTop:"40px", marginBottom:"80px"}} className="container">
                    <h5> Extintores de México </h5>
                    <p style={{margin:"0px"}}>Prolongacion avenida del Coli 232, CP 45069 Zapopan Jalisco</p>
                    <p>Correo: extintores@deltaindustria.com.mx</p>
                    <p style={{margin:"0px", textAlign:"center", fontWeight:"bold"}}>Contamos con servicio a domicilio llamanos.</p>
                    <p style={{margin:"0px"}}>
                        Tel <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523319333785?text=¡Hola buen día! ¿Me puedes brindar información de los productos?">(01)33 1933 3785</a>,
                        <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523316777084?text=¡Hola buen día! ¿Me puedes brindar información de los productos?"> (01)33 1677 7084</a>
                    </p>
                    <p style={{margin:"0px"}}>
                        Tel <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523317293750?text=¡Hola buen día! ¿Me puedes brindar información de los productos?">(01)33 1729 3750</a>,
                        <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523316750110?text=¡Hola buen día! ¿Me puedes brindar información de los productos?"> (01)331 675 0110</a>
                    </p>
                    <p style={{margin:"0px"}}>
                        Tel <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523315057590?text=¡Hola buen día! ¿Me puedes brindar información de los productos?">(01)33 1505 7590</a>,
                        <a rel="noopener noreferrer" target="_blank" href="https://wa.me/523315055638?text=¡Hola buen día! ¿Me puedes brindar información de los productos?"> (01)33 1505 5638</a>
                    </p>
                </div>

            </Fragment>
        )
    }
}

export default App;