import React, {Component, Fragment} from 'react';

import img1 from "../Images/1.png";
import img2 from "../Images/2.png";

class App extends Component {

  constructor(props){
    super(props);
    this.state = {}
  }

  render() {
    return(
      <Fragment>
        <div className="contenedor row center">
          <h2>¿Estás en busca de un gabinete para equipo de bomberos?</h2>
          <div className="col s12 m4 l4">
            <img src={img1} alt="Extintores para empresas" style={{width:"80%"}} />
          </div>
          <div className="col s12 m8 l8">
            <p>
              Extintores de México es una empresa orgullosamente certificada por protección civil 
              de México y se dedica a la comercialización de equipo de seguridad industria como lo son:
            </p>
            <p>
              -	Gabinetes para bomberos y mangueras contra incendios <br />
              -	Artículos de protección personal: guantes, gafas, arneses etc. <br />
              -	Botiquín de primero auxilios: venta de botiquines certificados <br />
              -	Señalizaciones de seguridad: señalamiento botiquín, salidas de emergencia, precauciones etc. <br />
              -	Bote arenero contra incendio <br />
              -	Arenero contra incendio <br />
              -	Extintores para empresas y su servicio completo: venta, mantenimiento, relleno y revisión 
              de extintores, descubre la mejor recarga de extintores en Guadalajara.
            </p>
          </div>
        </div>
        <div className="contenedor row center">
          <div className="col s12 m8 l8" style={{backgroundColor:"#9E2123", padding:"10px 20px"}}>
            <p>
              Como empresa destacamos en la venta de extintores, y varios servicios para estos mismos como 
              el mantenimiento y revisión de extintore. Contamos desde un extintor de polvo abc hasta 
              un extintor industrial de 70 kilos. Contamos con recarga de extintores en Zapopan totalmente 
              certificada por protección civil.
            </p>
            <p>
              También contamos con distintos capacitaciones y cursos en protección civil a los que podrás 
              asistir para saber cada cuanto se debe de recargar un extintor o saber que hacer en caso de 
              incendio y reducir al mínimo el riesgo y perdidas de cualquier tipo.
            </p>
          </div>
          <div className="col s12 m4 l4">
            <img src={img2} alt="extintores en Guadalajara" style={{width:"80%"}} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default App;
