import React, {Component} from 'react';

export default class Redes extends Component{

    constructor(props){
        super(props);
        this.state = {}
    }

    render(){
        return [
            <div id="panel" style={{position:"fixed", zIndex:"997", backgroundColor:"#FF8F00", color:"#000", fontWeight:"bold", borderRadius:"10px", padding:"0 20px", marginRight:"2%", bottom:"20px", left:"23px"}}>
                <p style={{margin:"0", fontSize:"15px", padding:"10px"}}>Brindamos servicio a toda la república</p>
            </div>
        ]
    }

}