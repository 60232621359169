import React, {Fragment, Component} from 'react';
import M from 'materialize-css';

import logo from '../Images/logo1.png';

class Header extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount = () => {
        console.log(this.props);
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems);
    }

    render(){
        return[
            <Fragment>
                <nav style={{background:"#ffffff00", boxShadow:"none"}}>
                    <div class="nav-wrapper" id="burgerMenu">
                        <a id="panel" href="/"><img src={logo} alt="" style={{width:"78px"}} /></a>
                        <a href="#" data-target="mobile-demo" class="sidenav-trigger" style={{color:"#B90606"}}><i class="fas fa-bars fa-3x"></i></a>
                        <ul class="right hide-on-med-and-down">
                            <li><a className="header-a" href="/">Inicio</a></li>
                            <li><a className="header-a" href="/nosotros">Nosotros</a></li>
                            <li><a className="header-a" href="/catalogo">Productos</a></li>
                            <li><a className="header-a" href="/servicios">Servicios</a></li>
                            <li><a className="header-a" href="/blogs">Blog</a></li>
                            <li><a className="header-a" href="/contacto">Contacto</a></li>
                        </ul>
                    </div>
                </nav>

                <ul class="sidenav" id="mobile-demo">
                    <li>
                        <a href="/"><img src={logo} alt="" style={{width:"78px"}} /></a>
                    </li>
                    <li><a href="/">Inicio</a></li>
                    <li><a href="/nosotros">Nosotros</a></li>
                    <li><a href="/catalogo">Productos</a></li>
                    <li><a href="/servicios">Servicios</a></li>
                    <li><a href="/blogs">Blog</a></li>
                    <li><a href="/contacto">Contacto</a></li>
                </ul>
            </Fragment>
        ];
    }
}

export default Header;